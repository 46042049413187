import {
    ProjectState,
    type DeliverableState,
    type Project,
    type ProjectStatus,
} from '@/gql/graphql';
import type {
    FrameworkAction,
    FrameworkArea,
    FrameworkAxesEntity,
    FrameworkData,
    FrameworkFocus,
    FrameworkNeed,
    FrameworkProjectPin,
} from '@/types/framework';
import { defineStore } from 'pinia';
import { computed, reactive, ref, watchEffect, nextTick } from 'vue';

export type ProjectPinColors = {
    type: string;
    status: string;
    department?: string;
    program?: string;
};

export interface PortfolioFilters {
    projectStatus: ProjectStatus[];
    projectState: ProjectState[];
    deliverableState: DeliverableState[];
    programs: string[];
    projectManagers: string[];
    departments: string[];
    tagValues: string[];
}

export type FrameworkProjectPinExtended = FrameworkProjectPin & {
    colors: ProjectPinColors;
};

export type FrameworkDataExtended = Omit<FrameworkData, 'pins'> & {
    name: string;
    pins: FrameworkProjectPinExtended[];
};

const initialState = {
    name: '',
    organisation: {
        logoUrl: undefined,
        name: '',
    },
    axes: {
        x: {
            labels: {
                roles: '',
                actions: '',
                focus: '',
            },
            roles: [],
            actions: [],
            focus: [],
        },
        y: {
            // tooltipLabel: '',
            labels: [],
            drivers: [],
            groups: [],
            needs: [],
        },
    },
    pins: [],
    programs: [],
    themes: [],
} satisfies FrameworkDataExtended;

export type FrameworkViewMode = 'department' | 'program' | 'type' | 'status';

export type PinTypeColors = {
    [K in ProjectStatus]: string;
};

export type PinStatusColors = {
    [K in
        | 'pending'
        | 'on_hold'
        | 'bottleneck'
        | 'escalation'
        | 'finished']: string;
};

export interface FrameworkSettings {
    cellHeight: number;
    pinColors: {
        irrelevant: string;
        type: PinTypeColors;
        status: PinStatusColors;
    };
}

export interface FrameworkState {
    isReady: boolean;
    isLoading: boolean;
    isEditing: boolean;
    viewMode: FrameworkViewMode;
}

export type FrameworkMode = 'view' | 'edit';

type FrameworkViewKeys = {
    [K in FrameworkViewMode]: {
        color?: string;
        name?: string;
    };
};

export type PinColorProject = Pick<
    Project,
    | 'uuid'
    | 'status'
    | 'finished'
    | 'state'
    | 'deliverableState'
    | 'onHold'
    | 'programs'
    | 'departments'
>;

export interface FrameworkProject {
    displayData: FrameworkViewKeys;
}

export type PinFilter = {
    type: ProjectStatus;
    status: ProjectState;
    deliverableStatus: DeliverableState;
    programId?: string;
    projectManagerId?: string;
    departmentId?: string;
    tagIds: string[];
};

export const useFrameworkStore = defineStore('framework', () => {
    const framework = reactive<FrameworkDataExtended>(initialState);
    const projects = ref(new Map<string, FrameworkViewKeys>());

    function sortYAxis(axis: FrameworkData['axes']['y']) {
        const { drivers, groups, needs } = axis;

        drivers.sort((a, b) => a.position - b.position);
        groups.sort((a, b) => a.position - b.position);

        needs.sort((a, b) => {
            return a.position - b.position;
        });
    }

    function sortActionsAndRoles(roles: FrameworkAxesEntity[]) {
        const sortedActions: FrameworkAction[] = [];
        const sortedFocus: FrameworkFocus[] = [];

        roles.sort((a, b) => a.position - b.position);

        roles.forEach((role) => {
            const actions = framework.axes.x.actions
                .filter((a) => a.roleId === role.id)
                .sort((a, b) => a.position - b.position);
            sortedActions.push(...actions);
        });

        framework.axes.x.actions = sortedActions;

        sortedActions.forEach((action, index) => {
            const f = framework.axes.x.focus.find(
                (fo) => fo.actionId === action.id
            );
            if (!f) return;

            sortedFocus[index] = f;
        });

        framework.axes.x.focus = sortedFocus;
    }

    function initialize(data: FrameworkDataExtended) {
        reset();

        nextTick(() => {
            Object.assign(framework, data);
            sortActionsAndRoles(framework.axes.x.roles);
            sortYAxis(framework.axes.y);
            state.isReady = true;
        });
    }

    function setFrameworkName(name: string) {
        framework.name = name;
    }

    function reset() {
        Object.assign(framework, initialState);
        state.isReady = false;
    }

    // Filters
    const filters = ref<PortfolioFilters>({
        projectStatus: [],
        projectState: [ProjectState.ON_GOING, ProjectState.ON_HOLD],
        deliverableState: [],
        programs: [],
        projectManagers: [],
        departments: [],
        tagValues: [],
    });

    const filterCount = computed(() => {
        return Object.values(filters.value).reduce((acc, arr) => {
            return acc + arr.length;
        }, 0);
    });

    // Framework settings
    const settings = reactive<FrameworkSettings>({
        cellHeight: 56,
        pinColors: {
            irrelevant: '#888',
            type: {
                approved_idea: '#f7d92e',
                idea: '#ffa500',
                project: '#4caf50',
            },
            status: {
                pending: '#4caf50',
                on_hold: '#4b70af',
                bottleneck: '#ffa500',
                escalation: '#B3261E',
                finished: '#5E5D62',
            },
        },
    });

    // Framework working state
    const state = reactive<FrameworkState>({
        isReady: false,
        isLoading: false,
        isEditing: false,
        viewMode: 'type',
    });

    const mode = ref<FrameworkMode>('view');
    const pins = computed(() => framework.pins);
    const focus = computed(() => {
        const sorted: FrameworkFocus[] = [];

        actions.value.forEach((action, index) => {
            const f = framework.axes.x.focus.find(
                (fo) => fo.actionId === action.id
            );
            if (!f) return;

            sorted[index] = f;
        });

        return sorted;
    });
    const roles = computed(() => framework.axes.x.roles);
    const needs = computed(() => framework.axes.y.needs);
    const actions = computed(() => {
        const sorted: FrameworkAction[] = [];
        roles.value.forEach((role) => {
            const actions = framework.axes.x.actions
                .filter((a) => a.roleId === role.id)
                .sort((a, b) => a.position - b.position);
            sorted.push(...actions);
        });

        return sorted;
    });
    const rowCount = computed(() => framework.axes.y.needs.length ?? 0);
    const columnCount = computed(() => framework.axes.x.actions.length ?? 0);

    watchEffect(() => {
        state.isEditing = mode.value === 'edit';
    });

    function setLoading(b: boolean) {
        state.isLoading = b;
    }

    function setViewMode(mode: FrameworkViewMode) {
        state.viewMode = mode;
    }

    function setFrameworkMode(fwmode: FrameworkMode) {
        mode.value = fwmode;
        state.isEditing = fwmode === 'edit';
    }

    const pinFilterInformation = ref(
        new Map<
            string,
            {
                type: ProjectStatus;
                status: ProjectState;
                deliverableStatus: DeliverableState;
                programId?: string;
                projectManagerId?: string;
                departmentId?: string;
                tagIds: string[];
            }
        >()
    );

    function inFilters(pinId: string): boolean {
        const pinfo = pinFilterInformation.value.get(pinId);
        if (!pinfo) return false;

        // Utility function to check if a filter value matches
        const matchesFilter = <T>(
            filterValues: T[],
            projectValue: T | undefined
        ): boolean => {
            if (filterValues.length === 0) return true;
            if (projectValue === undefined) return false;
            return filterValues.includes(projectValue);
        };

        const matchesTags = (filterTags: string[], projectTags: string[]) => {
            if (!filterTags.length) return true;
            for (let i = 0; i < filterTags.length; i++) {
                if (projectTags.includes(filterTags[i])) {
                    return true;
                }
            }
            return false;
        };

        const {
            projectStatus,
            projectState,
            deliverableState,
            programs,
            projectManagers,
            departments,
            tagValues,
        } = filters.value;

        if (!matchesFilter(projectStatus, pinfo.type)) return false;
        if (!matchesFilter(projectState, pinfo.status)) return false;
        if (!matchesFilter(deliverableState, pinfo.deliverableStatus))
            return false;
        if (!matchesFilter(programs, pinfo.programId)) return false;
        if (!matchesFilter(projectManagers, pinfo.projectManagerId))
            return false;
        if (!matchesFilter(departments, pinfo.departmentId)) return false;
        if (!matchesTags(tagValues, pinfo.tagIds)) return false;

        return true;
    }

    function addPins(pins: FrameworkProjectPinExtended[]) {
        framework.pins = [...framework.pins, ...pins];
    }

    function addThemes(themes: FrameworkArea[]) {
        framework.themes = [...framework.themes, ...themes];
    }

    function getPinColor(id: string, viewMode: FrameworkViewMode) {
        return (
            projects?.value?.get(id)?.[viewMode]?.color ??
            settings.pinColors.irrelevant
        );
    }

    function getPinLegend(id: string, viewMode: FrameworkViewMode) {
        return projects.value.get(id)?.[viewMode];
    }

    function getFocusFromCellIndex(index: number) {
        const { col } = getIndicesFromIndex(index);

        return focus.value[col];
    }
    function getNeedFromCellIndex(index: number) {
        const { row } = getIndicesFromIndex(index);

        return needs.value[row];
    }

    function getIndicesFromIndex(index: number) {
        return {
            row: Math.floor(index / columnCount.value),
            col: index % columnCount.value,
        };
    }

    function getIndexFromIndices(row: number, col: number) {
        const index = row * columnCount.value + col;
        return index;
    }

    return {
        actions,
        addPins,
        addThemes,
        columnCount,
        filterCount,
        filters,
        focus,
        framework,
        getIndicesFromIndex,
        getIndexFromIndices,
        getFocusFromCellIndex,
        getNeedFromCellIndex,
        getPinColor,
        getPinLegend,
        inFilters,
        initialize,
        mode,
        needs,
        pins,
        pinFilterInformation,
        projects,
        reset,
        roles,
        rowCount,
        settings,
        setLoading,
        setFrameworkMode,
        setFrameworkName,
        setViewMode,
        state,
    };
});
