import { graphql } from '@/gql';
import { useI18n } from 'vue-i18n';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useApolloClient, useMutation } from '@vue/apollo-composable';
import { useRefHistory } from '@vueuse/core';
import { useFrameworkStore } from '@/stores/framework';
import { useUserSettingsStore } from '@/stores/user-settings';
import { useNotificationStore } from '@/stores/notification';
import * as frameworkUtils from '@/utils/framework';

import type {
    CollectionType,
    Direction,
    FrameworkAction,
    FrameworkData,
    FrameworkNeed,
    HorizontalCollectionType,
} from '@/types/framework';
import type {
    UpdatePortfolioInput,
    UpdatePortfolioItemsInput,
    InsertPortfolioColumnsInput,
    InsertPortfolioRowsInput,
    DeletePortfolioRowsInput,
    DeletePortfolioColumnsInput,
    DeletePortfolioItemsInput,
    NeedInput,
} from '@/gql/graphql';

type ExpandOptions = {
    dir: Direction;
    collection: CollectionType | HorizontalCollectionType;
    repeat: number;
};

export const useFrameworkEditStore = defineStore('framework-edit', () => {
    const { t } = useI18n({
        messages: {
            nl: {
                update_success: 'Aanpassingen zijn opgeslagen',
                update_failure: 'Aanpassingen konden niet worden opgeslagen',
                row_not_empty: 'Rij is niet leeg.',
                column_not_empty: 'Kolom is niet leeg.',
            },
            en: {
                update_success: 'Changes successfully saved',
                update_failure: 'Unable to save changes',
                row_not_empty: 'Row is not empty.',
                column_not_empty: 'Column is not empty',
            },
        },
    });

    const { resolveClient } = useApolloClient();
    const userSettingsStore = useUserSettingsStore();
    const notificationStore = useNotificationStore();
    const frameworkStore = useFrameworkStore();
    const { setFrameworkMode } = useFrameworkStore();
    const { framework: originalFramework, mode } = storeToRefs(frameworkStore);

    const _portfolioId = ref<string | undefined>(undefined);
    const axes = ref<FrameworkData['axes'] | null>(null);

    const isDirty = computed(() => {
        if (mode.value !== 'edit') return false;

        if (portfolioName.value !== originalFramework.value.name) {
            return true;
        }

        if (axes.value) {
            return _compareAxes().length > 0;
        }

        if (
            newActions.value.length ||
            newFocuses.value.length ||
            newNeeds.value.length ||
            newRoles.value.length ||
            removedNeeds.value ||
            removedActions.value.length ||
            removedFocuses.value.length ||
            removedRoles.value.length ||
            removedDrivers.value.length ||
            removedGroups.value.length
        ) {
            return true;
        }

        return false;
    });

    const differences = computed(() => {
        if (axes.value) {
            return _compareAxes();
        }
        return [];
    });

    async function saveTitles() {
        if (!_portfolioId.value) {
            console.error('Missing required portfolio id');
            return;
        }

        const input = differences.value.map((item) => ({
            id: item.id,
            name: item.modification,
        }));

        return updatePortfolioItems({
            input: {
                portfolioId: _portfolioId.value,
                items: input,
            } satisfies UpdatePortfolioItemsInput,
        });
    }

    const newRoles = computed(() => {
        if (!axes.value) return [];

        const originalIds = originalFramework.value.axes.x.roles.map(
            (r) => r.id
        );

        return axes.value.x.roles.filter(
            (role) => !originalIds.includes(role.id)
        );
    });

    const newRolesInput = computed(() => {
        if (!axes.value) return [];

        return newRoles.value.map((role) => {
            const actions = newActions.value.map((action) => {
                const focus = newFocuses.value.find(
                    (focus) => focus.actionId === action.id
                );

                if (!focus)
                    throw Error(`No focus matching action ${action.id}`);

                return {
                    name: action.name,
                    focus: {
                        name: focus.name,
                    },
                };
            });

            return {
                name: role.name,
                action: actions,
                position: role.position,
            };
        });
    });

    const updatedActions = computed(() => {
        if (!axes.value) return [];

        const updated: FrameworkAction[] = [];

        axes.value.x.actions.forEach((action) => {
            const original = originalFramework.value.axes.x.actions.find(
                (a) => a.id === action.id
            );

            if (
                original &&
                (original.position !== action.position ||
                    original.roleId !== action.roleId)
            ) {
                updated.push(action);
            }
        });

        return updated;
    });

    const updatedActionsInput = computed(() => {
        return updatedActions.value.map((a) => ({
            id: a.id,
            name: a.name,
            parentId: a.roleId,
            position: a.position,
        }));
    });

    async function saveUpdatedActions() {
        if (!_portfolioId.value) return;

        await updatePortfolioItems({
            input: {
                portfolioId: _portfolioId.value,
                items: updatedActionsInput.value,
            } satisfies UpdatePortfolioItemsInput,
        });
    }

    const newActions = computed(() => {
        if (!axes.value) return [];
        const originalIds = originalFramework.value.axes.x.actions.map(
            (a) => a.id
        );

        return axes.value.x.actions.filter(
            (action) => !originalIds.includes(action.id)
        );
    });

    const newActionsInput = computed(() => {
        if (!axes.value) return [];

        const originalRoles = originalFramework.value.axes.x.roles.map(
            (r) => r.id
        );

        const newActionsWithExistingRole = newActions.value.filter((a) =>
            originalRoles.includes(a.roleId)
        );

        return newActionsWithExistingRole.map((action) => {
            const focus = newFocuses.value.find(
                (focus) => focus.actionId === action.id
            );

            if (!focus) throw Error(`No focus matching action ${action.id}`);

            return {
                roleId: action.roleId,
                name: action.name,
                position: action.position,
                focus: {
                    name: focus.name,
                },
            };
        });
    });

    const newFocuses = computed(() => {
        if (!axes.value) return [];
        const originalIds = originalFramework.value.axes.x.focus.map(
            (a) => a.id
        );

        return axes.value.x.focus.filter(
            (focus) => !originalIds.includes(focus.id)
        );
    });

    const newDrivers = computed(() => {
        if (!axes.value?.y) return [];

        const originalIds = originalFramework.value.axes.y.drivers.map(
            (d) => d.id
        );

        return axes.value.y.drivers.filter(
            (driver) => !originalIds.includes(driver.id)
        );
    });

    const newGroups = computed(() => {
        if (!axes.value?.y) return [];

        const originalIds = originalFramework.value.axes.y.groups.map(
            (d) => d.id
        );

        return axes.value.y.groups.filter(
            (group) => !originalIds.includes(group.id)
        );
    });

    const newNeeds = computed(() => {
        if (!axes.value) return [];

        const newGroupIds = newGroups.value.map((ng) => ng.id);
        const newDriverIds = newDrivers.value.map((nd) => nd.id);

        const originalIds = originalFramework.value.axes.y.needs.map(
            (n) => n.id
        );

        return axes.value.y.needs
            .filter((need) => !originalIds.includes(need.id))
            .filter(
                (fn) =>
                    !newDriverIds.includes(fn.driverId) &&
                    !newGroupIds.includes(fn.groupId)
            );
    });

    const updatedNeeds = computed(() => {
        if (!axes.value) return [];

        const updated: FrameworkNeed[] = [];

        axes.value.y.needs.forEach((need) => {
            const original = originalFramework.value.axes.y.needs.find(
                (n) => n.id === need.id
            );

            if (
                original &&
                (original.position !== need.position ||
                    original.driverId !== need.driverId ||
                    original.groupId !== need.groupId)
            ) {
                updated.push(need);
            }
        });

        return updated;
    });

    const updatedNeedsInput = computed(() => {
        return updatedNeeds.value.map((n) => ({
            id: n.id,
            name: n.name,
            parentId: n.groupId,
            position: n.position,
            driverId: n.driverId,
        }));
    });

    const newDriversInput = computed((): NeedInput[] => {
        if (!axes.value?.y) return [];
        const needs = axes.value?.y.needs;

        const input: NeedInput[] = [];

        newDrivers.value.forEach((nd) => {
            const relatedNeed = needs.find((n) => n.driverId === nd.id);
            if (!relatedNeed) return;

            input.push({
                name: relatedNeed.name,
                position: relatedNeed.position,
                groupId: relatedNeed.groupId,
                driver: {
                    name: nd.name,
                    position: nd.position,
                },
            } satisfies NeedInput);
        });

        return input;
    });

    const newGroupsInput = computed((): NeedInput[] => {
        if (!axes.value?.y) return [];
        const needs = axes.value?.y.needs;

        const input: NeedInput[] = [];

        newGroups.value.forEach((ng) => {
            const relatedNeed = needs.find((n) => n.groupId === ng.id);
            if (!relatedNeed) return;

            input.push({
                name: relatedNeed.name,
                position: relatedNeed.position,
                driverId: relatedNeed.driverId,
                group: {
                    name: ng.name,
                    position: ng.position,
                },
            } satisfies NeedInput);
        });

        return input;
    });

    const newNeedsInput = computed(() => {
        const newGroupIds = newGroups.value.map((g) => g.id);
        const newDriverIds = newDrivers.value.map((d) => d.id);

        return newNeeds.value
            .filter(
                (nn) =>
                    !newGroupIds.includes(nn.groupId) ||
                    !newDriverIds.includes(nn.driverId)
            )
            .map((n) => ({
                name: n.name,
                position: n.position,
                driverId: n.driverId,
                groupId: n.groupId,
            }));
    });

    async function saveUpdatedNeeds() {
        if (!_portfolioId.value) throw Error('No portfolio id set');

        if (updatedNeeds.value.length > 0) {
            await updatePortfolioItems({
                input: {
                    portfolioId: _portfolioId.value,
                    items: updatedNeedsInput.value,
                } satisfies UpdatePortfolioItemsInput,
            });
        }
    }

    async function saveNewColumns() {
        if (!_portfolioId.value) throw Error('No portfolio id set');

        if (newRolesInput.value.length > 0) {
            await insertColumns({
                input: {
                    portfolioId: _portfolioId.value,
                    role: newRolesInput.value,
                } satisfies InsertPortfolioColumnsInput,
            });
        }

        if (newActionsInput.value.length > 0) {
            await insertColumns({
                input: {
                    portfolioId: _portfolioId.value,
                    action: newActionsInput.value,
                },
            });
        }
    }

    async function saveRemovedDrivers() {
        if (!_portfolioId.value) throw Error('No portfolio id set');

        await deleteDrivers({
            input: {
                portfolioId: _portfolioId.value,
                items: removedDrivers.value,
            } satisfies DeletePortfolioItemsInput,
        });
    }

    async function saveRemovedGroups() {
        if (!_portfolioId.value) throw Error('No portfolio id set');

        await deleteGroups({
            input: {
                portfolioId: _portfolioId.value,
                items: removedGroups.value,
            } satisfies DeletePortfolioItemsInput,
        });
    }

    async function saveRemovedColumns() {
        if (!_portfolioId.value) throw Error('No portfolio id set');

        if (removedActions.value.length || removedFocuses.value.length) {
            await deleteColumns({
                input: {
                    portfolioId: _portfolioId.value,
                    columns: removedFocuses.value.map((f) => ({
                        cellId: f,
                    })),
                } satisfies DeletePortfolioColumnsInput,
            });
        }
    }

    async function saveNewDrivers() {
        if (!_portfolioId.value) throw Error('No portfolio id set');

        if (newDrivers.value.length > 0) {
            await insertRows({
                input: {
                    portfolioId: _portfolioId.value,
                    need: newDriversInput.value,
                } satisfies InsertPortfolioRowsInput,
            });
        }
    }

    async function saveNewGroups() {
        if (!_portfolioId.value) throw Error('No portfolio id set');

        if (newGroups.value.length > 0) {
            await insertRows({
                input: {
                    portfolioId: _portfolioId.value,
                    need: newGroupsInput.value,
                } satisfies InsertPortfolioRowsInput,
            });
        }
    }

    async function saveNewRows() {
        if (!_portfolioId.value) throw Error('No portfolio id set');

        if (newNeedsInput.value.length > 0) {
            await insertRows({
                input: {
                    portfolioId: _portfolioId.value,
                    need: newNeedsInput.value,
                } satisfies InsertPortfolioRowsInput,
            });
        }
    }

    async function saveRemovedRows() {
        if (!_portfolioId.value) return;

        if (removedNeeds.value.length > 0) {
            await deleteRows({
                input: {
                    portfolioId: _portfolioId.value,
                    rows: removedNeeds.value.map((a) => ({
                        cellId: a,
                    })),
                } satisfies DeletePortfolioRowsInput,
            });
        }
    }

    async function saveRemovedRoles() {
        if (!_portfolioId.value) throw Error('No portfolio id set');

        await deleteRoles({
            input: {
                portfolioId: _portfolioId.value,
                items: removedRoles.value,
            } satisfies DeletePortfolioItemsInput,
        });
    }

    const getRemovedIds = (
        currentArray: { id: string }[] | undefined,
        originalArray: { id: string }[]
    ) => {
        const currentIds = currentArray?.map((item) => item.id) ?? [];
        const originalIds = originalArray.map((item) => item.id);
        return originalIds.filter((id) => !currentIds.includes(id));
    };

    const removedRoles = computed(() =>
        getRemovedIds(axes.value?.x.roles, originalFramework.value.axes.x.roles)
    );

    const removedActions = computed(() =>
        getRemovedIds(
            axes.value?.x.actions,
            originalFramework.value.axes.x.actions
        )
    );

    const removedFocuses = computed(() =>
        getRemovedIds(axes.value?.x.focus, originalFramework.value.axes.x.focus)
    );

    const removedDrivers = computed(() =>
        getRemovedIds(
            axes.value?.y.drivers,
            originalFramework.value.axes.y.drivers
        )
    );

    const removedGroups = computed(() =>
        getRemovedIds(
            axes.value?.y.groups,
            originalFramework.value.axes.y.groups
        )
    );

    const removedNeeds = computed(() =>
        getRemovedIds(axes.value?.y.needs, originalFramework.value.axes.y.needs)
    );

    function _compareAxes() {
        if (!axes.value) return [];

        const originalAxes = originalFramework.value.axes;

        const differences: {
            id: string;
            original: string;
            modification: string;
        }[] = [];

        const og = [
            ...originalAxes.x.labels,
            ...originalAxes.x.roles,
            ...originalAxes.x.actions,
            ...originalAxes.x.focus,
            ...originalAxes.y.labels,
            ...originalAxes.y.drivers,
            ...originalAxes.y.groups,
            ...originalAxes.y.needs,
        ];

        const modified = [
            ...axes.value.x.labels,
            ...axes.value.x.roles,
            ...axes.value.x.actions,
            ...axes.value.x.focus,
            ...axes.value.y.labels,
            ...axes.value.y.drivers,
            ...axes.value.y.groups,
            ...axes.value.y.needs,
        ];

        modified.forEach((item) => {
            const original = og.find((i) => i.id === item.id);
            if (!original || original.name === item.name) return;

            differences.push({
                original: original.name,
                modification: item.name,
                id: item.id,
            });
        });

        return differences;
    }

    watch(mode, (newVal) => {
        if (newVal === 'edit') {
            setInitialState();
        }
    });

    function setInitialState() {
        portfolioName.value = originalFramework.value.name;
        axes.value = JSON.parse(JSON.stringify(originalFramework.value.axes));
        clear();
    }

    const portfolioName = ref<string>('');
    const { undo, clear } = useRefHistory(portfolioName);

    function setPortfolioName(name: string) {
        portfolioName.value = name.trim();
    }

    async function savePortfolioName() {
        if (!_portfolioId.value) {
            console.error('Missing required portfolio id');
            return;
        }

        return updatePortfolioName({
            input: {
                portfolioId: _portfolioId.value,
                name: portfolioName.value.trim(),
            } satisfies UpdatePortfolioInput,
        });
    }

    async function saveChanges(portfolioId: string) {
        _portfolioId.value = portfolioId;

        try {
            if (portfolioName.value !== originalFramework.value.name) {
                await savePortfolioName();

                userSettingsStore.setActivePortfolio({
                    name: portfolioName.value.trim(),
                    id: userSettingsStore.activePortfolio?.id as string,
                });

                notificationStore.add({
                    type: 'success',
                    message: t('update_success'),
                });
            }
            if (differences.value.length) {
                await saveTitles();

                notificationStore.add({
                    type: 'success',
                    message: t('update_success'),
                });
            }

            if (updatedActions.value.length) {
                await saveUpdatedActions();
            }
            if (newRoles.value || newActions.value) {
                await saveNewColumns();
            }

            if (newNeeds.value) {
                await saveNewRows();
            }

            if (newDrivers.value.length) {
                await saveNewDrivers();
            }

            if (newGroups.value.length) {
                await saveNewGroups();
            }

            if (updatedNeeds.value.length) {
                await saveUpdatedNeeds();
            }

            if (removedNeeds.value) {
                await saveRemovedRows();
            }

            if (removedActions.value.length || removedFocuses.value.length) {
                await saveRemovedColumns();
            }

            if (removedRoles.value.length) {
                await saveRemovedRoles();
            }

            if (removedDrivers.value.length) {
                await saveRemovedDrivers();
            }

            if (removedGroups.value.length) {
                await saveRemovedGroups();
            }
        } catch (err) {
            console.error(err);
            notificationStore.add({
                type: 'error',
                message: t('update_failure'),
            });
        } finally {
            finalizeEdit();
        }
    }

    function cancel() {
        setInitialState();
        setFrameworkMode('view');
    }

    async function finalizeEdit() {
        const client = resolveClient();
        setFrameworkMode('view');
        setInitialState();
        await client.refetchQueries({
            include: ['Portfolio', 'PortfolioSelector', 'Framework'],
        });
        clear();
    }

    function expand(id: string, options: ExpandOptions) {
        const { dir, collection, repeat } = options;

        if (!axes.value) throw Error(`Axes undefined`);

        let target = axes.value?.x.roles;
        if (collection === 'role') target = axes.value?.x.roles;
        if (collection === 'group') target = axes.value?.y.groups;
        if (collection === 'driver') target = axes.value?.y.drivers;

        if (!target) {
            throw Error(
                `Unable to expand: collection ${collection} not found.`
            );
        }

        // Expand horizontally
        if (['left', 'right'].includes(dir)) {
            expandRole(id, dir as 'left' | 'right');
        }

        if (['up', 'down'].includes(dir)) {
            if (collection === 'driver') expandDriver(id, dir as 'up' | 'down');
            if (collection === 'group') expandGroup(id, dir as 'up' | 'down');
        }

        if (repeat > 1) {
            expand(id, {
                collection,
                dir,
                repeat: repeat - 1,
            });
        }
    }

    function expandDriver(id: string, dir: 'up' | 'down') {
        if (!axes.value) throw Error(`Axes undefined`);
        const index = axes.value.y.drivers.findIndex((d) => d.id === id);
        // Dragging on vertical axis
        const increment = dir === 'up' ? -1 : 1;
        const nextItemIndex = index + increment;
        const nextItemId = axes.value.y.drivers[nextItemIndex].id;
        if (!nextItemId) throw Error(`No ${dir} collection found`);

        const nextNeeds = axes.value.y.needs.filter(
            (need) => need.driverId === nextItemId
        );

        if (nextNeeds.length <= 1) return;
        if (!nextNeeds.length) return;

        const nextNeed =
            dir === 'down' ? nextNeeds[0] : nextNeeds[nextNeeds.length - 1];
        nextNeed.driverId = id;
    }

    function expandGroup(id: string, dir: 'up' | 'down') {
        if (!axes.value) throw Error(`Axes undefined`);
        const index = axes.value.y.groups.findIndex((d) => d.id === id);

        // Dragging on vertical axis
        const increment = dir === 'up' ? -1 : 1;
        const nextItemIndex = index + increment;
        const nextItemId = axes.value.y.groups[nextItemIndex].id;
        if (!nextItemId) throw Error(`No ${dir} collection found`);

        const nextNeeds = axes.value.y.needs.filter(
            (need) => need.groupId === nextItemId
        );

        if (nextNeeds.length <= 1) return;
        if (!nextNeeds.length) return;

        const nextNeed =
            dir === 'down' ? nextNeeds[0] : nextNeeds[nextNeeds.length - 1];
        nextNeed.groupId = id;
    }

    function expandRole(id: string, dir: 'left' | 'right') {
        if (!axes.value?.x) throw Error(`x-axis is undefined`);
        const index = axes.value.x.roles.findIndex((r) => r.id === id);

        // Prevent expanding at edges.
        if (dir === 'left' && index === 0) return;
        if (dir === 'right' && index === axes.value.x.roles.length - 1) return;

        const increment = dir === 'left' ? -1 : 1;
        const nextIndex = index + increment;
        if (!axes.value.x.roles[nextIndex])
            throw Error(`No ${dir} collection found`);
        const nextId = axes.value.x.roles[nextIndex].id;

        const nextChildren = axes.value.x.actions.filter(
            (action) => action.roleId === nextId
        );

        // Prevent sibling role from running out of actions.
        if (nextChildren.length <= 1 || !nextChildren.length) return;

        const childToAdd =
            dir === 'right'
                ? nextChildren[0]
                : nextChildren[nextChildren.length - 1];

        childToAdd.roleId = id;
    }

    function addGroup(index: number, before: boolean = false) {
        if (!axes.value) throw Error('Axes not set');

        const position = before ? index : index + 1;

        frameworkUtils.insertGroup(axes.value.y, position);
    }

    function addDriver(index: number, before: boolean = false) {
        if (!axes.value) throw Error('Axes not set');

        const position = before ? index : index + 1;

        frameworkUtils.insertDriver(axes.value.y, position);
    }

    function addRow(index: number, before: boolean = false) {
        if (!axes.value) throw Error('Axes not set');
        const groupId = axes.value.y.needs[index].groupId;
        const driverId = axes.value.y.needs[index].driverId;

        if (!groupId)
            throw Error(`no groupId found for need with index ${index}`);

        if (!driverId)
            throw Error(`no driverId found for need with index ${index}`);

        const position = axes.value.y.needs[index].position;

        const need = {
            id: crypto.randomUUID(),
            name: 'Need',
            position: before ? position : position + 1,
            groupId,
            driverId,
        };

        const atIndex = before ? index : index + 1;
        axes.value.y.needs.splice(atIndex, 0, need);
    }

    function removeRow(index: number) {
        if (!axes.value) throw Error('Axes not set');

        const needToRemove = axes.value.y.needs[index];
        if (!needToRemove) throw Error('No need found at ' + index);

        // Check if there are projects attached
        if (
            frameworkStore.pins.some((pin) => pin.parents.y === needToRemove.id)
        ) {
            notificationStore.add({
                type: 'error',
                message: t('row_not_empty'),
            });
            return;
        }

        // Check if it's the last row in the driver/group
        const rowsInDriver = axes.value.y.needs.filter(
            (need) => need.driverId === needToRemove.driverId
        );

        if (rowsInDriver.length <= 1)
            return new Error("Can't remove last need in driver");

        axes.value.y.needs.splice(index, 1);
    }

    function removeColumn(index: number) {
        if (!axes.value) throw Error('Axes not set');

        const actionToRemove = axes.value.x.actions[index];
        const focusToRemove = axes.value.x.focus[index];

        if (!actionToRemove) throw Error('No action found at index: ' + index);
        if (!focusToRemove) throw Error('No focus found at index: ' + index);

        // Check if there are projects attached
        if (
            frameworkStore.pins.some(
                (pin) => pin.parents.x === focusToRemove.id
            )
        ) {
            notificationStore.add({
                type: 'error',
                message: t('column_not_empty'),
            });
            return;
        }

        const colsInRole = axes.value.x.actions.filter(
            (ac) => ac.roleId === actionToRemove.roleId
        );

        if (colsInRole.length <= 1)
            return new Error("Can't remove last column in role");

        axes.value.x.actions.splice(index, 1);
        axes.value.x.focus.splice(index, 1);
    }

    function removeDriver(index: number) {
        if (!axes.value) throw Error('Axes not set');

        const driverToRemove = axes.value.y.drivers[index];

        if (!driverToRemove)
            throw Error(`No driver found with at index ${index}`);

        //  Find all needs with driver, attach to different driver.
        const needsInDriver = axes.value.y.needs.filter(
            (n) => n.driverId === driverToRemove.id
        );

        const newParent =
            index === 0
                ? axes.value.y.drivers[1]
                : axes.value.y.drivers[index - 1];

        needsInDriver.forEach((need) => (need.driverId = newParent.id));
        axes.value.y.drivers.splice(index, 1);
    }

    function removeGroup(index: number) {
        if (!axes.value) throw Error('Axes not set');

        const groupToRemove = axes.value.y.groups[index];

        if (!groupToRemove)
            throw Error(`No group found with at index ${index}`);

        //  Find all needs with group, attach to different group.
        const needsInGroup = axes.value.y.needs.filter(
            (n) => n.groupId === groupToRemove.id
        );

        const newParent =
            index === 0
                ? axes.value.y.groups[1]
                : axes.value.y.groups[index - 1];

        needsInGroup.forEach((need) => (need.groupId = newParent.id));
        axes.value.y.groups.splice(index, 1);
    }

    function findColumnPosition(columnIndex: number) {
        if (!axes.value) throw Error('Axes not set');

        return axes.value.x.actions[columnIndex].position;
    }

    function addRole(index: number, before: boolean = false) {
        if (!axes.value) throw Error('Axes not set');
        const atIndex = before ? index : index + 1;

        frameworkUtils.insertRole({
            xAxis: axes.value.x,
            index: atIndex,
            before,
        });
    }

    function removeRole(index: number) {
        if (!axes.value) throw Error('Axes not set');

        const itemToRemove = axes.value.x.roles[index];

        if (!itemToRemove)
            throw Error(`No strategic role found with at index ${index}`);

        //  Find all needs with role, attach to different role.
        const actionsInRole = axes.value.x.actions.filter(
            (n) => n.roleId === itemToRemove.id
        );

        let newParent =
            index === 0 ? axes.value.x.roles[1] : axes.value.x.roles[index - 1];

        if (!newParent) newParent = axes.value.x.roles[0];

        actionsInRole.forEach((action) => (action.roleId = newParent.id));
        axes.value.x.roles.splice(index, 1);
    }

    function addColumn(index: number, before: boolean = false) {
        if (!axes.value) throw Error('Axes not set');

        const roleId = axes.value?.x.actions[index].roleId;
        if (!roleId)
            throw Error(`Unable to find role for action index ${index}`);

        // Decide psition
        let position = findColumnPosition(index);
        if (!before) position += 1;

        frameworkUtils.insertColumn(axes.value.x, roleId, position);
    }

    function moveRow(index: number, before: boolean = false) {
        if (!axes.value) throw Error('Axes not set');

        if (axes.value.y.needs.length - 1 === index && !before) {
            console.warn('Can not move the last item down');
            return;
        }
        if (index === 0 && before) {
            console.warn('Can not move the first item up');
            return;
        }

        const needToMove = axes.value.y.needs[index];
        const insertAt = before ? index - 1 : index + 1;

        // Check if need has to change its group and/or role id.
        const currentNeedAtPosition = axes.value.y.needs[insertAt];

        if (currentNeedAtPosition.groupId !== needToMove.groupId)
            needToMove.groupId = currentNeedAtPosition.groupId;
        if (currentNeedAtPosition.driverId !== needToMove.driverId)
            needToMove.driverId = currentNeedAtPosition.driverId;

        const [need] = axes.value.y.needs.splice(index, 1);

        needToMove.position = before
            ? needToMove.position - 1
            : needToMove.position + 1;

        currentNeedAtPosition.position = before
            ? currentNeedAtPosition.position + 1
            : currentNeedAtPosition.position - 1;

        axes.value.y.needs.splice(insertAt, 0, need);
    }

    function moveColumn(index: number, before: boolean = false) {
        if (!axes.value) throw Error('Axes not set');

        if (axes.value.x.actions.length - 1 === index && !before) {
            console.warn('Can not move the last item right');
            return;
        }
        if (index === 0 && before) {
            console.warn('Can not move the first item left');
            return;
        }

        const insertAt = before ? index - 1 : index + 1;
        const [action] = axes.value.x.actions.splice(index, 1);
        const [focus] = axes.value.x.focus.splice(index, 1);

        axes.value.x.actions.splice(insertAt, 0, action);
        axes.value.x.focus.splice(insertAt, 0, focus);

        action.position = insertAt;
    }

    function setItemName(
        type:
            | 'roles'
            | 'actions'
            | 'focus'
            | 'drivers'
            | 'groups'
            | 'needs'
            | 'label_x'
            | 'label_y',
        id: string,
        value: string
    ) {
        const typeToListMap = {
            label_x: axes.value?.x.labels,
            roles: axes.value?.x.roles,
            actions: axes.value?.x.actions,
            focus: axes.value?.x.focus,
            label_y: axes.value?.y.labels,
            drivers: axes.value?.y.drivers,
            groups: axes.value?.y.groups,
            needs: axes.value?.y.needs,
        };

        const targetList = typeToListMap[type];
        if (!targetList) throw Error(`Invalid type: ${type}`);

        const target = targetList.find((t) => t.id === id);
        if (!target) throw Error(`Unable to find id ${id} in ${type}`);

        target.name = value;
    }

    // Mutations
    const { mutate: updatePortfolioName } = useMutation(
        graphql(`
            mutation UpdatePortfolio($input: UpdatePortfolioInput!) {
                updatePortfolio(input: $input) {
                    portfolio {
                        id
                    }
                }
            }
        `)
    );

    const { mutate: updatePortfolioItems } = useMutation(
        graphql(`
            mutation UpdatePortfolioItems($input: UpdatePortfolioItemsInput!) {
                updatePortfolioItems(input: $input) {
                    ok
                }
            }
        `)
    );

    const { mutate: insertColumns } = useMutation(
        graphql(`
            mutation InsertPortfolioColumns(
                $input: InsertPortfolioColumnsInput!
            ) {
                insertPortfolioColumns(input: $input) {
                    ok
                }
            }
        `)
    );

    const { mutate: deleteColumns } = useMutation(
        graphql(`
            mutation DeletePortfolioColumns(
                $input: DeletePortfolioColumnsInput!
            ) {
                deletePortfolioColumns(input: $input) {
                    ok
                }
            }
        `)
    );

    const { mutate: insertRows } = useMutation(
        graphql(`
            mutation InsertPortfolioRows($input: InsertPortfolioRowsInput!) {
                insertPortfolioRows(input: $input) {
                    ok
                }
            }
        `)
    );

    const { mutate: deleteRows } = useMutation(
        graphql(`
            mutation DeletePortfolioRows($input: DeletePortfolioRowsInput!) {
                deletePortfolioRows(input: $input) {
                    ok
                }
            }
        `)
    );

    const { mutate: deleteDrivers } = useMutation(
        graphql(`
            mutation DeletePortfolioDrivers(
                $input: DeletePortfolioItemsInput!
            ) {
                deletePortfolioDrivers(input: $input) {
                    ok
                }
            }
        `)
    );
    const { mutate: deleteGroups } = useMutation(
        graphql(`
            mutation DeletePortfolioGroups($input: DeletePortfolioItemsInput!) {
                deletePortfolioGroups(input: $input) {
                    ok
                }
            }
        `)
    );
    const { mutate: deleteRoles } = useMutation(
        graphql(`
            mutation DeletePortfolioRoles($input: DeletePortfolioItemsInput!) {
                deletePortfolioRoles(input: $input) {
                    ok
                }
            }
        `)
    );

    return {
        axes,
        differences,
        isDirty,
        newRoles,
        newActions,
        newFocuses,
        newDrivers,
        newGroups,
        newNeeds,
        portfolioName,
        updatedNeeds,
        updatedActions,
        addColumn,
        addDriver,
        addGroup,
        addRole,
        addRow,
        cancel,
        expand,
        moveColumn,
        moveRow,
        removeColumn,
        removeRow,
        removeRole,
        removeGroup,
        removeDriver,
        saveChanges,
        setInitialState,
        setItemName,
        setPortfolioName,
        undo,
    };
});
