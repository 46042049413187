import type {
    FrameworkAxesEntity,
    FrameworkData,
    FrameworkNeed,
} from '@/types/framework';

type XAxis = FrameworkData['axes']['x'];
type YAxis = FrameworkData['axes']['y'];

type FrameworkEditRole = {
    id: `${string}-${string}-${string}-${string}-${string}`;
    name: string;
    position: number;
};

export function createRole(
    name: string = 'role',
    position: number = 0
): FrameworkEditRole {
    return {
        id: crypto.randomUUID(),
        name,
        position,
    };
}

export function createAction(input: {
    name?: string;
    roleId: string;
    position?: number;
}) {
    return {
        id: crypto.randomUUID(),
        name: input.name || 'action',
        position: input.position || 0,
        roleId: input.roleId,
    };
}

export function createFocus(input: { actionId: string; name?: string }) {
    return {
        id: crypto.randomUUID(),
        name: input.name || 'focus',
        actionId: input.actionId,
        position: 0,
    };
}

export function createDriver(
    name: string = 'driver',
    position: number = 0
): FrameworkAxesEntity {
    return {
        id: crypto.randomUUID(),
        name,
        position,
    };
}

export function createGroup(
    name: string = 'group',
    position: number = 0
): FrameworkAxesEntity {
    return {
        id: crypto.randomUUID(),
        name,
        position,
    };
}

export function createNeed(
    driverId: string,
    groupId: string,
    position: number = 0
): FrameworkNeed {
    return {
        id: crypto.randomUUID(),
        driverId,
        groupId,
        position,
        name: 'need',
    };
}

/**
 * Inserts a role in the provided framework x-axis object
 */
export function insertRole(input: {
    xAxis: XAxis;
    index: number;
    before?: boolean;
}) {
    const { xAxis, index, before } = input;
    const _role = createRole(undefined, index);

    // New role can't be empty, add action + focus
    const action = createAction({ roleId: _role.id });
    const focus = createFocus({ actionId: action.id });

    // Find the position to add the action + focus
    const referenceRole = role(xAxis, index);
    if (!referenceRole) throw Error('No role found at index ' + index);

    const actionInsertIndex = before
        ? referenceRole.firstAction.index
        : referenceRole.lastAction.index + 1;

    xAxis.actions.splice(actionInsertIndex, 0, action);
    xAxis.focus.splice(actionInsertIndex, 0, focus);
    xAxis.roles.splice(index, 0, _role);
}

/**
 * @param xAxis The x-axis object to mutate
 * @param index Where to insert the column
 * @param roleId
 */
export function insertColumn(xAxis: XAxis, roleId: string, position: number) {
    const _role = role(xAxis, roleId);
    if (!_role) throw Error('No role found with id ' + roleId);

    const action = createAction({
        roleId,
        position,
    });

    const focus = createFocus({
        actionId: action.id,
    });

    xAxis.actions.splice(position, 0, action);
    xAxis.focus.splice(position, 0, focus);
}

export function insertYAxisParent(
    axis: YAxis,
    position: number = 0,
    entity: 'driver' | 'group'
) {
    // Create driver object
    const newParent =
        entity === 'group'
            ? createGroup('group', position)
            : createDriver('driver', position);
    const newParentContainer = entity === 'group' ? axis.groups : axis.drivers;

    // Insert in position
    if (entity === 'driver') {
        axis.drivers.splice(position, 0, newParent);
    } else {
        axis.groups.splice(position, 0, newParent);
    }

    const index = newParentContainer.findIndex((p) => p.id === newParent.id);
    let needRef: FrameworkNeed | undefined;

    // Find last need of previous driver or first need of next driver
    if (index === 0) {
        // Take the first need from index 1
        if (entity === 'group') {
            needRef = axis.needs.find((n) => n.groupId === axis.groups[1].id);
        } else {
            needRef = axis.needs.find((n) => n.driverId === axis.drivers[1].id);
        }
    } else {
        // Take the last need from index - 1;
        if (entity === 'group') {
            needRef = axis.needs
                .filter((n) => n.groupId === axis.groups[index - 1].id)
                .reduce((a, b) => (a.position > b.position ? a : b));
        } else {
            needRef = axis.needs
                .filter((n) => n.driverId === axis.drivers[index - 1].id)
                .reduce((a, b) => (a.position > b.position ? a : b));
        }
    }

    if (!needRef) {
        throw Error('Unable to find need reference');
    }

    const insertAt = index === 0 ? 0 : axis.needs.indexOf(needRef) + 1;

    // Create a need for the new driver or group.
    const placeholderNeed: FrameworkNeed = {
        name: 'NEW NEED',
        position: insertAt,
        id: crypto.randomUUID(),
        groupId: '',
        driverId: '',
    };

    if (entity === 'group') {
        placeholderNeed.groupId = newParent.id;
        placeholderNeed.driverId = needRef!.driverId;
    } else {
        placeholderNeed.groupId = needRef!.groupId;
        placeholderNeed.driverId = newParent.id;
    }

    insertNeed(axis, placeholderNeed);

    // // Attach to new driver
    // if (entity === 'group') {
    //     needRef!.groupId = newParent.id;
    // } else {
    //     needRef!.driverId = newParent.id;
    // }
}

export function insertNeed(axis: YAxis, need: FrameworkNeed) {
    axis.needs.forEach((n) => {
        if (n.position >= need.position) {
            n.position++;
        }
    });

    console.log({ insert_at: need.position });

    axis.needs.splice(need.position, 0, need);
}

export function insertGroup(axis: YAxis, position: number = 0) {
    insertYAxisParent(axis, position, 'group');
}

export function insertDriver(axis: YAxis, position: number = 0) {
    insertYAxisParent(axis, position, 'driver');
}

type XAxisItem = 'role' | 'action' | 'focus';
type YAxisItem = 'driver' | 'group' | 'need';

const axisTypeToArrayMap: Record<
    XAxisItem | YAxisItem,
    keyof XAxis | keyof YAxis
> = {
    role: 'roles',
    action: 'actions',
    focus: 'focus',
    driver: 'drivers',
    group: 'groups',
    need: 'needs',
};

function _findItemOnAxis<T extends XAxis | YAxis>(
    axis: T,
    type: T extends XAxis ? XAxisItem : YAxisItem,
    indexOrId: string | number
) {
    const arrayKey = axisTypeToArrayMap[type];
    const array = axis[arrayKey as keyof T];

    if (!Array.isArray(array)) {
        return undefined;
    }

    return typeof indexOrId === 'number'
        ? array[indexOrId]
        : array.find((item) => item.id === indexOrId);
}

function _findRole(axis: XAxis, idOrIndex: string | number) {
    return _findItemOnAxis(axis, 'role', idOrIndex);
}
function _findAction(axis: XAxis, idOrIndex: string | number) {
    return _findItemOnAxis(axis, 'action', idOrIndex);
}
function _findFocus(axis: XAxis, idOrIndex: string | number) {
    return _findItemOnAxis(axis, 'focus', idOrIndex);
}

function _findDriver(
    axis: YAxis,
    idOrIndex: string | number
): FrameworkAxesEntity {
    return _findItemOnAxis(axis, 'driver', idOrIndex);
}

function _findGroup(axis: YAxis, idOrIndex: string | number) {
    return _findItemOnAxis(axis, 'group', idOrIndex);
}

function _findNeed(axis: YAxis, idOrIndex: string | number) {
    return _findItemOnAxis(axis, 'need', idOrIndex);
}

function _getActionsByRole(xAxis: XAxis, roleId: string) {
    return xAxis.actions.filter((action) => action.roleId === roleId);
}
function _getFocusesByRole(xAxis: XAxis, roleId: string) {
    const actions = _getActionsByRole(xAxis, roleId);
    return xAxis.focus.filter((focus) =>
        actions.map((a) => a.id).includes(focus.actionId)
    );
}

function _getNeedsByDriver(axis: YAxis, driverId: string) {
    return axis.needs.filter((need) => need.driverId === driverId);
}

function _getNeedsByGroup(axis: YAxis, groupId: string) {
    return axis.needs.filter((need) => need.groupId === groupId);
}

export function group(axis: YAxis, idOrIndex: string | number) {
    const group = _findGroup(axis, idOrIndex);
    if (!group) return undefined;

    const index = axis.groups.findIndex((g) => g.id === group.id);
    const needs = _getNeedsByGroup(axis, group.id);
    const firstNeed = needs[0];
    const lastNeed = needs[needs.length - 1];

    return {
        ...group,
        index,
        needs,
        firstNeed,
        lastNeed,
    };
}

export function getDriver(axis: YAxis, idOrIndex: string | number) {
    const driver = _findDriver(axis, idOrIndex);
    if (!driver) return undefined;

    const index = axis.drivers.findIndex((d) => d.id === driver.id);
    const driverNeeds = _getNeedsByDriver(axis, driver.id);
    const firstNeed = driverNeeds[0];
    const lastNeed = driverNeeds[driverNeeds.length - 1];

    return {
        ...driver,
        index,
        needs: driverNeeds,
        firstNeed,
        lastNeed,
    };
}

export function role(
    xAxis: FrameworkData['axes']['x'],
    idOrIndex: string | number
) {
    const role = _findRole(xAxis, idOrIndex);
    if (!role) return null;

    const roleActions = _getActionsByRole(xAxis, role.id);
    const roleFocuses = _getFocusesByRole(xAxis, role.id);

    const firstAction = xAxis.actions.find((action) => {
        return action.position === 0 && action.roleId === role.id;
    });

    const lastAction = xAxis.actions.find((action) => {
        return (
            action.position === roleActions.length - 1 &&
            action.roleId === role.id
        );
    });

    let firstActionIndex = -1;
    let lastActionIndex = -1;

    if (firstAction) {
        firstActionIndex = xAxis.actions.indexOf(firstAction);
    }
    if (lastAction) {
        lastActionIndex = xAxis.actions.indexOf(lastAction);
    }

    return {
        index: xAxis.roles.indexOf(role),
        id: role.id,
        position: role.position,
        name: role.name,
        actions: roleActions,
        focuses: roleFocuses,
        firstAction: {
            ...firstAction,
            index: firstActionIndex,
        },
        lastAction: {
            ...lastAction,
            index: lastActionIndex,
        },
    };
}
